@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
:root {
  --amplify-primary-color: #319795;
  --amplify-primary-tint: #68c8c5;
  --amplify-primary-shade: #006867;
  --amplify-secondary-color: #6b46c1;
  --amplify-secondary-tint: #6b46c1;
  --amplify-secondary-shade: #361b90;
  --amplify-font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7fafc;
}

#root {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

iframe.forestry {
  min-height: 500px;
}

@media screen and (min-width: 1440px){
  iframe.forestry {
    min-height: 620px;
  }
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.page-exit {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.custom-html-style :first-child {
  margin-top: 0 !important;
}

.custom-html-style :last-child {
  margin-bottom: 0 !important;
}

.pipeline-filters section[id*='popover']{
  -webkit-transform: translate3d(230px, 0, 0) !important;
          transform: translate3d(230px, 0, 0) !important;
}

pre {
  font-size: 0.85rem !important;
  background: inherit !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #319795;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2C7A7B;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideUp {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
  }
}

@keyframes slideUp {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
  }
}

